export class CuentasModel {
  constructor() {
    this.idCuenta = null;
    this.email = null;
    this.estado = null;
    this.contrasena = null;
    this.cantidadUso = null;
    this.idTipoCuenta = new TipoCuentaModel();
  }

  idCuenta?: any;
  email?: any;
  estado?: any;
  contrasena?: any;
  cantidadUso?: any;
  idTipoCuenta?: TipoCuentaModel;
}

export class CuentasModelExcel {


  idCuenta?: any;
  email?: any;
  estado?: any;
  contrasena?: any;
  cantidadUso?: any;
  idTipoCuenta?: TipoCuentaModel;
}

export class BeanCombinados {
  constructor() {
    this.fechaInicial = null;
    this.fechaFinal = null;
  }

  fechaInicial?: Date;
  fechaFinal?: Date;
}

export class TipoCuentaModel {
  constructor() {
    this.idTipoCuenta = null;
    this.nomTipoCuenta = null;
    this.cantidadPantallas = null;
    this.mostrarPerfil = null;
    this.estado = null;
    this.pathImage = null;
    this.mensajeVenta = null;
    this.valorCuenta = null;
    this.valorCuentaDistribuidor = null;
  }

  idTipoCuenta?: any;
  nomTipoCuenta?: any;
  cantidadPantallas;
  mostrarPerfil;
  estado?: any;
  pathImage?: any;
  mensajeVenta?: any;
  valorCuenta?: any;
  valorCuentaDistribuidor?: any;
}


export class ConteoCuentasModel {
  constructor() {
    this.idTipoCuenta = null;
    this.nomTipoCuenta = null;
    this.cantidadDisponible = null;

  }

  idTipoCuenta?: any;
  nomTipoCuenta?: any;
  cantidadDisponible;

}

export class BeanVentasExcel {
  constructor() {
    this.email = null;
    this.nombreTipoCuenta = null;
    this.perfil = null;
    this.nombrePunto = null;
    this.fechaAsignacion = null;
    this.valor = null;
    this.equipo = null;
    this.idUsuarioLider = null;
  }
  email?: any;
  nombreTipoCuenta?: any;
  perfil?: any;
  equipo?: any;
  fechaAsignacion?: any;
  valor?: any;
  nombrePunto?: any;
  idUsuarioLider?: any;
}

export class CuentasExcelModel {
  titulo?: any;
  area?: any;
  documentos?: any;
  titulares?: any;
  telefono?: any;
  correspondencia?: any;
  idmodalidad?: any;
  municipios?: any;
  minerales?: any;
  tieneDocumentacion?: any;
  indactivo?: any;
  fechaRadicacionCmc?: any;
  latitud?: any;
  longitud?: any;
  idTipo?: any;
}
