
export class CategoriasModel {
    constructor() {
        this.idCategoria = null;
        this.nomCategoria = null;
        this.tipoCategoria = null;
        this.estadoCategoria = null;
    }

    idCategoria?: any;
    nomCategoria?: any;
    tipoCategoria?: any;
    estadoCategoria?: any;

}
